import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logos/Fridge Repair Transparent.png';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8 sm:py-12 mt-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          <div className="flex flex-col items-center sm:items-start">
            <img src={logo} alt="Local Fridge Repair Logo" className="h-12 sm:h-16 mb-4 object-contain" />
            <p className="mb-4 text-center sm:text-left text-sm">Expert commercial refrigeration repair services across London and surrounding areas.</p>
          </div>
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-bold mb-4">Contact Us</h3>
            <p className="mb-2 text-sm">Phone: <a href="tel:+442078702206" className="hover:text-primary-500 transition duration-300">0207 870 2206</a></p>
            <p className="text-sm">Email: <a href="mailto:info@localfridgerepair.com" className="hover:text-primary-500 transition duration-300">info@localfridgerepair.com</a></p>
          </div>
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2 text-sm">
              <li><Link to="/" className="hover:text-primary-500 transition duration-300">Home</Link></li>
              <li><Link to="/services" className="hover:text-primary-500 transition duration-300">Services</Link></li>
              <li><Link to="/service-areas" className="hover:text-primary-500 transition duration-300">Service Areas</Link></li>
              <li><Link to="/about" className="hover:text-primary-500 transition duration-300">About Us</Link></li>
              <li><Link to="/contact" className="hover:text-primary-500 transition duration-300">Contact</Link></li>
            </ul>
          </div>
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-bold mb-4">Service Areas</h3>
            <ul className="space-y-2 text-sm">
              <li><Link to="/service-areas" className="hover:text-primary-500 transition duration-300">London</Link></li>
              <li><Link to="/service-areas/essex" className="hover:text-primary-500 transition duration-300">Essex</Link></li>
              <li><Link to="/service-areas/hertfordshire" className="hover:text-primary-500 transition duration-300">Hertfordshire</Link></li>
              <li><Link to="/service-areas/kent" className="hover:text-primary-500 transition duration-300">Kent</Link></li>
              <li><Link to="/service-areas/surrey" className="hover:text-primary-500 transition duration-300">Surrey</Link></li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-700 pt-8">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="mb-6 sm:mb-0 text-center sm:text-left">
              <h3 className="text-lg font-bold mb-4">Connect With Us</h3>
              <div className="flex justify-center sm:justify-start space-x-6">
                <a href="#" className="text-white hover:text-primary-500 transition duration-300">
                  <i className="fab fa-facebook-f text-2xl" aria-hidden="true"></i>
                  <span className="sr-only">Facebook</span>
                </a>
                <a href="#" className="text-white hover:text-primary-500 transition duration-300">
                  <i className="fab fa-twitter text-2xl" aria-hidden="true"></i>
                  <span className="sr-only">Twitter</span>
                </a>
                <a href="#" className="text-white hover:text-primary-500 transition duration-300">
                  <i className="fab fa-linkedin-in text-2xl" aria-hidden="true"></i>
                  <span className="sr-only">LinkedIn</span>
                </a>
                <a href="#" className="text-white hover:text-primary-500 transition duration-300">
                  <i className="fab fa-instagram text-2xl" aria-hidden="true"></i>
                  <span className="sr-only">Instagram</span>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-auto">
              <h3 className="text-lg font-bold mb-4 text-center sm:text-left">Newsletter</h3>
              <form className="flex flex-col sm:flex-row">
                <input type="email" placeholder="Your email" className="py-2 px-3 w-full rounded-md sm:rounded-r-none mb-2 sm:mb-0 focus:outline-none focus:ring-2 focus:ring-primary-500 text-gray-800" />
                <button type="submit" className="bg-primary-500 text-white py-2 px-4 rounded-md sm:rounded-l-none hover:bg-primary-600 transition duration-300">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-sm">
          <p>&copy; {new Date().getFullYear()} Local Fridge Repair. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
