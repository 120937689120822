import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { pricing } from '../config';
import logo from '../assets/logos/Fridge Repair Transparent.png';
import { InputWithIcon, CheckboxWithLabel } from './FormComponents';
import RepairForm from './RepairForm';
import MaintenanceForm from './MaintenanceForm';
import InstallationForm from './InstallationForm';
import { validateField, validateStep } from '../utils/formValidation';

const ServiceIcon = ({ icon, label, selected, onClick }) => (
  <motion.div
    className={`flex flex-col items-center justify-center p-2 sm:p-4 rounded-lg cursor-pointer transition duration-200 ${
      selected ? 'bg-primary-100 border-2 border-primary-500' : 'bg-gray-100 hover:bg-gray-200'
    }`}
    onClick={onClick}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <i className={`fas ${icon} text-2xl sm:text-3xl mb-1 sm:mb-2 ${selected ? 'text-primary-500' : 'text-gray-600'}`}></i>
    <span className={`text-xs sm:text-sm font-medium ${selected ? 'text-primary-600' : 'text-gray-700'}`}>{label}</span>
  </motion.div>
);

const StepIndicator = ({ currentStep, totalSteps, setStep }) => {
  const icons = ["fa-tools", "fa-user", "fa-building", "fa-clock", "fa-snowflake", "fa-pound-sign"];
  
  return (
    <div className="flex justify-center items-center mb-4 sm:mb-6 overflow-x-auto">
      {[...Array(totalSteps)].map((_, index) => (
        <div key={index} className="flex items-center">
          <motion.div
            className={`w-6 h-6 sm:w-8 sm:h-8 md:w-12 md:h-12 rounded-full flex items-center justify-center ${
              index + 1 === currentStep ? 'bg-primary-500 text-white' : 'bg-gray-300 text-gray-600'
            } cursor-pointer`}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
            onClick={() => setStep(index + 1)}
          >
            <i className={`fas ${icons[index]} text-xs sm:text-sm md:text-base ${index + 1 === currentStep ? 'text-white' : 'text-gray-600'}`}></i>
          </motion.div>
          {index < totalSteps - 1 && (
            <motion.div
              className="w-4 sm:w-8 md:w-16 h-1 bg-gray-300 mx-1"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 0.3, delay: (index + 1) * 0.1 }}
            >
              <motion.div
                className={`h-full ${index + 1 < currentStep ? 'bg-primary-500' : 'bg-gray-300'}`}
                initial={{ scaleX: 0 }}
                animate={{ scaleX: currentStep > index + 1 ? 1 : 0 }}
                transition={{ duration: 0.3 }}
              />
            </motion.div>
          )}
        </div>
      ))}
    </div>
  );
};

const LoaderAnimation = () => (
  <motion.div
    className="flex flex-col justify-center items-center h-40"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <motion.img 
      src={logo} 
      alt="Fridge Repair Logo" 
      className="w-24 h-24 sm:w-32 sm:h-32 mb-4"
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
    />
    <motion.div
      className="w-10 h-10 sm:w-12 sm:h-12 border-t-4 border-primary-500 border-solid rounded-full"
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
    />
  </motion.div>
);

const BookingForm = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const totalSteps = 6;
  const [formData, setFormData] = useState({
    serviceType: '',
    name: '',
    email: '',
    phone: '',
    businessName: '',
    buildingNameNumber: '',
    town: '',
    postcode: '',
    siteOpenTime: '',
    siteCloseTime: '',
    urgent: false,
    serviceDate: '',
    fridgeType: '',
    fridgeBrand: '',
    faultDescription: '',
    maintenanceDetails: '',
    installationSpace: '',
    installationNotes: '',
  });
  const [errors, setErrors] = useState({});
  const [quote, setQuote] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showUrgentConfirmation, setShowUrgentConfirmation] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const styles = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `;
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'urgent' && checked) {
      setShowUrgentConfirmation(true);
    } else {
      const newValue = type === 'checkbox' ? checked : value;
      setFormData(prevData => ({ ...prevData, [name]: newValue }));
      setErrors(prevErrors => ({ ...prevErrors, [name]: validateField(name, newValue) }));
    }
  };

  const handleUrgentConfirmation = (confirmed) => {
    setShowUrgentConfirmation(false);
    setFormData(prevData => ({ ...prevData, urgent: confirmed, serviceDate: confirmed ? '' : prevData.serviceDate }));
  };

  const calculateQuote = () => {
    console.log('Calculating quote...');
    const urgentFee = formData.urgent ? pricing.urgentFee : 0;
    const basePrice = pricing.basePrice;
    const serviceTypeFee = pricing.serviceTypeFees[formData.serviceType.toLowerCase()] || 0;
    const total = basePrice + urgentFee + serviceTypeFee;
    
    const calculatedQuote = {
      basePrice,
      urgentFee,
      serviceTypeFee,
      total
    };
    console.log('Calculated quote:', calculatedQuote);
    return calculatedQuote;
  };

const sendBookingData = async (bookingData) => {
  console.log('Sending booking data:', bookingData);
  try {
    const apiUrl = process.env.REACT_APP_API_URL || 'https://localfridgerepair.com';
    const response = await fetch(`${apiUrl}/api/submit-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('Booking submission result:', result);
    return result.success;
  } catch (error) {
    console.error('Error submitting booking:', error);
    return false;
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted. Current step:', step);
    
    const stepErrors = validateStep(step, formData);
    setErrors(stepErrors);

    if (Object.keys(stepErrors).length > 0) {
      console.log('Validation failed for step:', step);
      return;
    }

    setIsLoading(true);

    if (step === totalSteps - 1) {
      console.log('Calculating quote...');
      const calculatedQuote = calculateQuote();
      setQuote(calculatedQuote);
      setStep(step + 1);
    } else if (step === totalSteps) {
      console.log('Sending booking data...');
      const bookingData = {
        ...formData,
        quoteDetails: quote,
      };

      try {
        const success = await sendBookingData(bookingData);
        
        if (success) {
          console.log('Booking submitted successfully');
          setSuccessMessage('Your booking has been submitted successfully! We will contact you shortly to confirm the details.');
          
          // Google Ads Conversion Tracking
          if (window.gtag) {
            window.gtag('event', 'conversion', {
              'send_to': 'AW-10894369835/RDATCMjThNwZEKvI68oo',
              'value': quote.total,
              'currency': 'GBP',
              'transaction_id': ''
            });
          }
          
          // Reset form data after successful submission
          setFormData({
            serviceType: '',
            name: '',
            email: '',
            phone: '',
            businessName: '',
            buildingNameNumber: '',
            town: '',
            postcode: '',
            siteOpenTime: '',
            siteCloseTime: '',
            urgent: false,
            serviceDate: '',
            fridgeType: '',
            fridgeBrand: '',
            faultDescription: '',
            maintenanceDetails: '',
            installationSpace: '',
            installationNotes: '',
          });
          setQuote(null);
          setStep(1);
        } else {
          throw new Error('Booking submission failed');
        }
      } catch (error) {
        console.error('Failed to submit booking:', error);
        setSuccessMessage(''); // Clear any existing success message
        alert(`There was an error submitting your booking: ${error.message}. Please try again.`);
      }
    } else {
      setStep(step + 1);
    }
    setIsLoading(false);
  };

  const formVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: 50, transition: { duration: 0.5 } },
  };

const renderStepContent = () => {
  switch (step) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4 sm:mb-6">Select Service Type</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4">
              <ServiceIcon
                icon="fa-wrench"
                label="Repair"
                selected={formData.serviceType === 'Repair'}
                onClick={() => setFormData(prevData => ({ ...prevData, serviceType: 'Repair' }))}
              />
              <ServiceIcon
                icon="fa-tools"
                label="Maintenance"
                selected={formData.serviceType === 'Maintenance'}
                onClick={() => setFormData(prevData => ({ ...prevData, serviceType: 'Maintenance' }))}
              />
              <ServiceIcon
                icon="fa-box"
                label="Installation"
                selected={formData.serviceType === 'Installation'}
                onClick={() => setFormData(prevData => ({ ...prevData, serviceType: 'Installation' }))}
              />
            </div>
            {errors.serviceType && <p className="text-red-500 mt-2 text-sm">{errors.serviceType}</p>}
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">Customer Contact Details</h3>
            <InputWithIcon
              icon="fa-user"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your Name"
              required
              label="Name"
              error={errors.name}
            />
            <InputWithIcon
              icon="fa-envelope"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Your Email"
              required
              label="Email"
              error={errors.email}
            />
            <InputWithIcon
              icon="fa-phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Your Phone"
              required
              label="Phone"
              error={errors.phone}
            />
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">Business Details</h3>
            <InputWithIcon
              icon="fa-building"
              type="text"
              name="businessName"
              value={formData.businessName}
              onChange={handleInputChange}
              placeholder="Your Business Name"
              required
              label="Business Name"
              error={errors.businessName}
            />
            <InputWithIcon
              icon="fa-home"
              type="text"
              name="buildingNameNumber"
              value={formData.buildingNameNumber}
              onChange={handleInputChange}
              placeholder="Building Name/Number"
              required
              label="Building Name/Number"
              error={errors.buildingNameNumber}
            />
            <InputWithIcon
              icon="fa-city"
              type="text"
              name="town"
              value={formData.town}
              onChange={handleInputChange}
              placeholder="Town"
              required
              label="Town"
              error={errors.town}
            />
            <InputWithIcon
              icon="fa-mail-bulk"
              type="text"
              name="postcode"
              value={formData.postcode}
              onChange={handleInputChange}
              placeholder="Postcode"
              required
              label="Postcode"
              error={errors.postcode}
            />
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">Site Details</h3>
            <InputWithIcon
              icon="fa-clock"
              type="time"
              name="siteOpenTime"
              value={formData.siteOpenTime}
              onChange={handleInputChange}
              required
              label="Site Opening Time"
              error={errors.siteOpenTime}
            />
            <InputWithIcon
              icon="fa-clock"
              type="time"
              name="siteCloseTime"
              value={formData.siteCloseTime}
              onChange={handleInputChange}
              required
              label="Site Closing Time"
              error={errors.siteCloseTime}
            />
            <CheckboxWithLabel
              name="urgent"
              checked={formData.urgent}
              onChange={handleInputChange}
              label="Urgent Request"
            />
            {!formData.urgent && (
              <InputWithIcon
                icon="fa-calendar"
                type="date"
                name="serviceDate"
                value={formData.serviceDate}
                onChange={handleInputChange}
                required
                label="Preferred Service Date"
                error={errors.serviceDate}
              />
            )}
          </motion.div>
        );
    case 5:
      return (
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.3 }}
          className="mb-20"
        >
          <h3 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6">Service Details</h3>
          {formData.serviceType === 'Repair' && (
            <RepairForm formData={formData} handleInputChange={handleInputChange} errors={errors} />
          )}
          {formData.serviceType === 'Maintenance' && (
            <MaintenanceForm formData={formData} handleInputChange={handleInputChange} errors={errors} />
          )}
          {formData.serviceType === 'Installation' && (
            <InstallationForm formData={formData} handleInputChange={handleInputChange} errors={errors} />
          )}
          <div className="mt-6 p-4 bg-gray-100 rounded-lg">
            <h4 className="font-semibold mb-2">Labour Rates Information:</h4>
            <p>Our base price covers the first hour of labour. After the first hour, we charge £48 per 30 minutes of additional labour.</p>
            <p className="mt-2 text-sm text-gray-600">Note: This charge does not include the cost of spare parts or refrigerant.</p>
          </div>
        </motion.div>
      );
      case 6:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
            className="max-h-[60vh] overflow-y-auto pr-2 sm:pr-4 custom-scrollbar"
          >
            <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4 sm:mb-6">Booking Summary and Quote</h3>
            {isLoading ? (
              <LoaderAnimation />
            ) : (
              <>
                <div className="bg-gray-100 p-3 sm:p-4 md:p-6 rounded-lg mb-4 sm:mb-6 shadow-inner text-xs sm:text-sm md:text-base">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                    <SummarySection
                      title="Service Details"
                      icon="fa-tools"
                      data={[
                        { label: "Service Type", value: formData.serviceType },
                        { label: "Urgent Request", value: formData.urgent ? 'Yes' : 'No' },
                        { label: "Service Date", value: formData.urgent ? 'ASAP' : formData.serviceDate },
                      ]}
                      onEdit={() => setStep(1)}
                    />
                    <SummarySection
                      title="Customer Details"
                      icon="fa-user"
                      data={[
                        { label: "Name", value: formData.name },
                        { label: "Email", value: formData.email },
                        { label: "Phone", value: formData.phone },
                      ]}
                      onEdit={() => setStep(2)}
                    />
                    <SummarySection
                      title="Business Details"
                      icon="fa-building"
                      data={[
                        { label: "Business Name", value: formData.businessName },
                        { label: "Address", value: `${formData.buildingNameNumber}, ${formData.town}, ${formData.postcode}` },
                      ]}
                      onEdit={() => setStep(3)}
                    />
                    <SummarySection
                      title="Site Details"
                      icon="fa-clock"
                      data={[
                        { label: "Site Open", value: formData.siteOpenTime },
                        { label: "Site Close", value: formData.siteCloseTime },
                      ]}
                      onEdit={() => setStep(4)}
                    />
                    <SummarySection
                      title="Fridge Details"
                      icon="fa-snowflake"
                      data={[
                        { label: "Fridge Type", value: formData.fridgeType },
                        { label: "Fridge Brand", value: formData.fridgeBrand },
                      ]}
                      onEdit={() => setStep(5)}
                    />
                  </div>
                  <div className="mt-3 sm:mt-4">
                    <h4 className="font-semibold mb-1 sm:mb-2"><i className="fas fa-comment mr-2"></i>Service Description:</h4>
                    <p className="mt-1 whitespace-pre-wrap text-xs sm:text-sm">
                      {formData.serviceType === 'Repair' && formData.faultDescription}
                      {formData.serviceType === 'Maintenance' && formData.maintenanceDetails}
                      {formData.serviceType === 'Installation' && `Installation Space: ${formData.installationSpace}\nNotes: ${formData.installationNotes}`}
                    </p>
                    <button
                      onClick={() => setStep(5)}
                      className="mt-2 text-primary-500 hover:text-primary-600 transition duration-150 text-xs sm:text-sm"
                    >
                      Edit
                    </button>
                  </div>
                </div>
                {quote && (
                  <motion.div
                    className="bg-primary-100 p-3 sm:p-4 md:p-6 rounded-lg shadow-md"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <h4 className="font-semibold mb-2 sm:mb-4 text-sm sm:text-base md:text-lg">Quote Details:</h4>
                    <p className="text-xs sm:text-sm"><i className="fas fa-pound-sign mr-2"></i>Base Price (covers first hour): £{quote.basePrice}</p>
                    {quote.urgentFee > 0 && <p className="text-xs sm:text-sm"><i className="fas fa-exclamation-circle mr-2"></i>Urgent Fee: £{quote.urgentFee}</p>}
                    {quote.serviceTypeFee > 0 && <p className="text-xs sm:text-sm"><i className="fas fa-cog mr-2"></i>Service Type Fee: £{quote.serviceTypeFee}</p>}
                    <p className="text-lg sm:text-xl md:text-2xl font-bold mt-2 sm:mt-4 text-primary-600"><i className="fas fa-calculator mr-2"></i>Initial Total: £{quote.total}</p>
                    <p className="text-xs sm:text-sm text-gray-600 mt-2">
                      Note: This quote covers the first hour of labor. Additional time will be charged at £48 per 30 minutes.
                    </p>
                    <p className="text-xs sm:text-sm text-gray-600 mt-2">
                      Please be aware that this charge does not include the cost of spare parts or refrigerant, which may be necessary for the repair.
                    </p>
                  </motion.div>
                )}
              </>
            )}
          </motion.div>
        );
      default:
        return null;
    }
  };

  const SummarySection = ({ title, icon, data, onEdit }) => (
    <div className="mb-3 sm:mb-4">
      <h4 className="font-semibold mb-1 sm:mb-2 text-xs sm:text-sm"><i className={`fas ${icon} mr-2`}></i>{title}</h4>
      {data.map((item, index) => (
        <p key={index} className="text-xs sm:text-sm"><strong>{item.label}:</strong> {item.value}</p>
      ))}
      <button
        onClick={onEdit}
        className="mt-1 sm:mt-2 text-primary-500 hover:text-primary-600 transition duration-150 text-xs sm:text-sm"
      >
        Edit
      </button>
    </div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-0 sm:p-4"
          onClick={onClose}
        >
          <motion.div
            variants={formVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="bg-white w-full h-full sm:h-auto sm:w-full sm:max-w-4xl sm:rounded-lg p-4 sm:p-6 md:p-8 overflow-y-auto custom-scrollbar relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={onClose}
              className="absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-500 hover:text-gray-700 transition duration-150"
              aria-label="Close"
            >
              <i className="fas fa-times text-lg sm:text-xl"></i>
            </button>
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 text-primary-600 mt-6 sm:mt-0">Book a Repair</h2>
            {successMessage ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-green-100 border-l-4 border-green-500 text-green-700 p-3 sm:p-4 mb-4"
                role="alert"
              >
                <p className="font-bold text-sm sm:text-base">Success!</p>
                <p className="text-xs sm:text-sm">{successMessage}</p>
              </motion.div>
            ) : (
              <>
                <StepIndicator currentStep={step} totalSteps={totalSteps} setStep={setStep} />
                <form onSubmit={handleSubmit} className="max-w-md sm:max-w-2xl mx-auto">
                  {renderStepContent()}
                  <motion.div
                    className="flex justify-between mt-4 sm:mt-6 md:mt-8 mb-4 sm:mb-6 md:mb-8 bg-white pt-2 sm:pt-4 z-40"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                  >
                    {step > 1 && step < totalSteps && (
                      <button
                        type="button"
                        onClick={() => setStep(step - 1)}
                        className="bg-gray-300 text-gray-700 py-2 px-3 sm:px-4 md:px-6 rounded-full hover:bg-gray-400 transition duration-150 text-xs sm:text-sm md:text-base"
                      >
                        Back
                      </button>
                    )}
                    {step < totalSteps - 1 && (
                      <button
                        type="submit"
                        className="bg-primary-500 text-white py-2 px-3 sm:px-4 md:px-6 rounded-full hover:bg-primary-600 transition duration-150 ml-auto text-xs sm:text-sm md:text-base"
                      >
                        Next
                      </button>
                    )}
                    {step === totalSteps - 1 && (
                      <button
                        type="submit"
                        className="bg-primary-500 text-white py-2 px-3 sm:px-4 md:px-6 rounded-full hover:bg-primary-600 transition duration-150 ml-auto text-xs sm:text-sm md:text-base"
                      >
                        Get Quote
                      </button>
                    )}
                    {step === totalSteps && (
                      <button
                        type="submit"
                        className="bg-primary-500 text-white py-2 px-3 sm:px-4 md:px-6 rounded-full hover:bg-primary-600 transition duration-150 ml-auto text-xs sm:text-sm md:text-base"
                      >
                        Confirm Booking
                      </button>
                    )}
                  </motion.div>
                </form>
              </>
            )}
          </motion.div>
        </motion.div>
      )}
      {showUrgentConfirmation && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-[60]"
        >
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-2xl max-w-md w-full mx-4"
          >
            <h3 className="text-lg sm:text-xl md:text-2xl font-bold mb-3 sm:mb-4 text-primary-600">Confirm Urgent Request</h3>
            <p className="mb-3 sm:mb-4 text-sm sm:text-base md:text-lg leading-relaxed text-gray-700">
              An urgent request will incur an additional fee of 
              <span className="font-bold text-primary-600 text-base sm:text-lg md:text-xl ml-1">£50</span>.
            </p>
            <p className="mb-3 sm:mb-4 text-sm sm:text-base md:text-lg leading-relaxed text-gray-700">
              This ensures priority handling of your repair.
            </p>
            <p className="mb-4 sm:mb-6 font-semibold text-sm sm:text-base md:text-lg text-gray-800">
              Do you want to proceed with the urgent request?
            </p>
            <div className="flex justify-end space-x-3 sm:space-x-4">
              <button
                onClick={() => handleUrgentConfirmation(false)}
                className="px-3 sm:px-4 md:px-6 py-2 sm:py-3 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-150 text-sm sm:text-base md:text-lg font-medium"
              >
                Cancel
              </button>
              <button
                onClick={() => handleUrgentConfirmation(true)}
                className="px-3 sm:px-4 md:px-6 py-2 sm:py-3 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition duration-150 text-sm sm:text-base md:text-lg font-medium"
              >
                Confirm Urgent
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BookingForm;
